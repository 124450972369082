import * as React from "react";
import { Link } from "gatsby";
import {
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem
} from "reactstrap";
import { HeaderLink } from "../models";
import tccLogo from "../images/tcc-logo-flat.png";

interface HeaderProps {
    siteTitle: string;
}

interface HeaderState {
    isOpen: boolean;
    links: HeaderLink[];
}

class Header extends React.Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        isOpen: false,
        links: [
            {
                path: "/",
                text: "Home",
                exact: true
            },
            {
                path: "/services",
                text: "Services",
                exact: false
            },
            {
                path: "/contract-vehicles",
                text: "Contract Vehicles",
                exact: false
            },
            {
                path: "/careers",
                text: "Careers",
                exact: false
            },
            {
                path: "/contact",
                text: "Contact",
                exact: false
            },
            {
                path: "/news",
                text: "News",
                exact: false
            }
        ]
    };

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { isOpen, links } = this.state;

        return (
            <header>
                <Navbar
                    color="white"
                    light
                    expand="md"
                    fixed="top"
                    style={{ minHeight: 64 }}
                >
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img
                                src={tccLogo}
                                alt="Tiber Creek Consulting"
                                height={40}
                                loading="lazy"
                            />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />

                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                {links.map(link => (
                                    <NavItem key={link.path}>
                                        <Link
                                            to={link.path}
                                            getProps={props =>
                                                props.isCurrent ||
                                                (props.isPartiallyCurrent &&
                                                    !link.exact)
                                                    ? {
                                                          className:
                                                              "nav-link active"
                                                      }
                                                    : { className: "nav-link" }
                                            }
                                        >
                                            {link.text}
                                        </Link>
                                    </NavItem>
                                ))}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default Header;
