import { Link } from "gatsby";
import * as React from "react";
import { Container } from "reactstrap";

const Footer: React.FunctionComponent = () => (
    <footer
        className="bg-dark text-light py-2 d-flex align-items-center justify-content-start"
        style={{ minHeight: 48 }}
    >
        <Container className="text-center">
            &copy; {new Date().getFullYear()} Tiber Creek Consulting, Inc.{" "}
            <span className="mx-2">|</span>{" "}
            <Link to="/privacy-policy" className="text-white">
                Privacy Policy
            </Link>
        </Container>
    </footer>
);

export default Footer;
